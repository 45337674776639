<!--
    This component is responsible for adding a consistent gutter around content.
    It will mostly commonly be used as the child of a <Box>,
    but it could be useful anywhere content needs to be separated from something like a border or image.
-->

<style>
    .box__content {
        margin: var(--gutter);
    }
</style>

<template>
    <div class="box__content">
        <slot />
    </div>
</template>

<script>
    export default {};
</script>
