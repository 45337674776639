<script>
  export default{
    name : 'PersonAddIcon',
  }
</script>

<template>
  <svg v-bind:class="{ mobile: $root.isMobile, 'person-add-icon' : true, phone : $root.isPhone }" fill="currentColor" id="person-add-outline" class="ionicon" viewBox="0 0 512 512">
    <path d="M376 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" stroke-linecap="round" stroke-linejoin="round" class="ionicon-fill-none ionicon-stroke-width"></path>
    <path d="M288 304c-87 0-175.3 48-191.64 138.6-2 10.92 4.21 21.4 15.65 21.4H464c11.44 0 17.62-10.48 15.65-21.4C463.3 352 375 304 288 304z" stroke-miterlimit="10" class="ionicon-fill-none ionicon-stroke-width"></path>
    <path stroke-linecap="round" stroke-linejoin="round" d="M88 176v112M144 232H32" stroke-width="35" stroke="currentColor">
    </path>
  </svg>
</template>

<style>
  svg.person-add-icon{
    width:20px;
    height:auto;
  }

  svg.person-add-icon.mobile{
    width: 18px;
    height: auto;
  }
  span.button-with-icon-content svg.mobile{
    padding-right:0px;
  }

  span.button-with-icon-content svg.phone {
    width: 18px;
    height: auto;
  }

  .social-feed-box-wrapper.mobile .button--secondary-gradient .button__content {
    padding:4px;
  }

</style>
