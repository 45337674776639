<script>
import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import { useUserStore } from '../../../store/useUserStore.js';
import { useHeaderStore } from '../../../store/useHeaderStore.js';

export default {
    name: 'CpHeader',
    components: {
        CpBaseSvgIcon: require('../base-svg-icon').default,
        CpButtonLink: require('../button-link').default,
        CpButton: require('../button').default,
        CpHeaderMenuTooltip: require('./header-menu-tooltip').default,
        CpSiteSearch: require('./search').default,
        CpSiteClosureBanner: require('./site-closure-banner').default,
        CpSiteChatNotification: require('./chat-notification').default,
    },
    directives: {
        marginBottom: require('../directives/margins').marginBottom,
        marginRight: require('../directives/margins').marginRight,
    },
    props: {
        sitewideContent: Object,
    },
    data() {
        return {
            path: window.location.pathname,
            sitewideCTA: this.sitewideContent['text'] ? this.sitewideContent['text'] : null,
            sitewideCTALink: this.sitewideContent['link'] ? this.sitewideContent['link'] : null,
            iosApp: process.env.MIX_IOS_STORE_URL,
            androidApp: process.env.MIX_ANDROID_STORE_URL,
            currentUrl: window.location.href,
            howWorksItems: [
                { url: '/how-it-works#campaign', icon: 'fa-podium', title: 'Campaign', description: 'Raise money for your candidancy, another candidate, or for a cause.' },
                { url: '/how-it-works#blog', icon: 'fa-edit', title: 'Blog Post', description: 'Write an article about political issues that are important to you.' },
                { url: '/how-it-works#petition', icon: 'fa-file-signature', title: 'Petition', description: 'Collect signatures from supporters and send final tally directly to politicians' },
                { url: '/how-it-works#lobby', icon: 'fa-landmark', title: 'Lobby', description: 'Endorse or oppose a representative by zip code to help spark local change.' },
                { url: '/how-it-works#rally', icon: 'fa-video', title: 'Virtual Rally', description: 'Hold a live QA for your constituents or supporters and collect donations.' },
                { url: '/how-it-works#livestream', icon: 'fa-signal-stream', title: 'Livestreams', description: 'Start a live conversation to share your thoughts with the community.' },
                { url: '/how-it-works#poll', icon: 'fa-poll', title: 'Poll', description: 'Find out what users think and make your own statistics.' },
                { url: '/how-it-works#group', icon: 'fa-user-friends', title: 'Group', description: 'Read news, lobby political candidates, and connect with related members.' },
                { url: '/how-it-works#members', icon: 'fa-users', title: 'Members', description: 'Take a look at our members and discover new connections' },
                { url: '/how-it-works#news', icon: 'fa-rss-square', title: 'News', description: 'Find and read the latest political news about candidacies, candidates, proposals and more.' },
            ],
            startItems: [
                { url: '/user/campaigns/create', icon: 'fa-podium', title: 'Campaign', description: 'Raise money for your candidancy, another candidate, or for a cause.' },
                { url: '/blogs/create', icon: 'fa-edit', title: 'Blog Post', description: 'Write an article about political issues that are important to you.' },
                { url: '/petitions/create', icon: 'fa-file-signature', title: 'Petition', description: 'Collect signatures from supporters and send final tally directly to politicians' },
                { url: '/lobbying', icon: 'fa-landmark', title: 'Lobby', description: 'Endorse or oppose a representative by zip code to help spark local change.' },
                { url: '/user/rallies', icon: 'fa-video', title: 'Virtual Rally', description: 'Hold a live QA for your constituents or supporters and collect donations.' },
                { url: '/livestreams/start', icon: 'fa-signal-stream', title: 'Livestreams', description: 'Start a live conversation to share your thoughts with the community.' },
                { url: '/polls/create', icon: 'fa-poll', title: 'Poll', description: 'Find out what users think and make your own statistics.' },
                { url: '/groups/create', icon: 'fa-user-friends', title: 'Group', description: 'Read news, lobby political candidates, and connect with related members.' },
                { url: '/events/create', icon: 'fa-calendar-day', title: 'Event', description: 'Organize an event to get together with your group and build a strong community.' },
            ],
            isOpenhamburguerMenu: false,
        };
    },
    computed: {
        ...mapState(useUserStore, ['isUserLogin', 'isUserLoginLoading', 'userLoginData']),
    },
    methods: {
        handleLogout() {
            localStorage.removeItem("userLoginData");
            sessionStorage.removeItem("userPlanStorage");
        },
        handleHamburguerMenu() {
            this.isOpenhamburguerMenu = !this.isOpenhamburguerMenu;
        },
        ...mapActions(useHeaderStore, ['setSitewideBannerInfo'])
    },
    updated() {
        if (this.sitewideCTA && this.sitewideCTALink && !this.path.startsWith('/contribute')) {
            const height = document.getElementById("banner").offsetHeight;
            this.setSitewideBannerInfo(height);
        }
    },
};
</script>

<template>
    <div v-if="!isUserLoginLoading"> <!-- Use inline styles so Vue and non - Vue play nice -->
        <a v-if="sitewideCTA && sitewideCTALink && !path.startsWith('/contribute')" id="banner" target="_blank"
            class="underline-hover"
            style="display:block; text-align: center; padding:10px; background: #ff4814; color: white; font-family: 'proxima-nova'; font-size:16px;"
            :href="sitewideCTALink">{{ sitewideCTA }}</a>
        <div class="site-header">
            <header id="js-site-header" class="site-header__main">

                <!-- mobile menu toggle hamburger -->
                <div id="js-mobile-menu-toggle" class="visible-phone menu-toggle-container">
                    <span @click="handleHamburguerMenu">
                        <i class="fal fa-bars primary-color fa-1-5x mobile-menu-toggle"></i>
                    </span>
                </div>

                <cp-site-search-toggle-button v-if="path !== '/user/login'" style="margin-left: 8px;"
                    class="visible-phone inline-button site-header__main-nav-item site-header__main-nav-item--icon-only site-search-icon">
                    <i class="fas fa-search fa-1-5x" title="Site search"></i>
                </cp-site-search-toggle-button>

                <div class="site-header__main-nav-spacer visible-phone"></div>

                <a href="/" class="site-header__main-nav-item site-header__main-nav-logo">
                    <img src="https://assets.crowdpac.com/v2/production/all/img/crowdpac-logo.png" class="site-header__logo" alt="Crowdpac" width="185" />
                </a>
                <!-- <a class="site-home-icon {{ Request::is('feed') || ( Request::url() == url('/') && user != null  ) ? 'active' : '' }}" href="{{route('social-feed.index')}}">
          <svg style="padding-top:7px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;" viewBox="0 0 407 488.75" x="0px" y="0px" fill-rule="evenodd" clip-rule="evenodd">
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style="stop-color:#f83600;stop-opacity:1" />
                <stop offset="100%" style="stop-color:#fe8c00;stop-opacity:1" />
              </linearGradient>
            </defs>
            <g>
              <polygon fill="url(#grad1)" points="170,291 170,391 66,391 66,217 0,217 203,0 407,217 340,217 340,391 237,391 237,291 "/>
            </g>
          </svg>
        </a> -->

                <div class="site-header__main-nav-spacer"></div>

                <div class="site-header__button-group">
                    <div class="site-header__main-nav-item site-header__main-nav-item--icon-only">
                        <div v-if="isUserLogin" class="dropdown-tooltip-menu">
                            <i class="fas fa-plus-circle primary-color fa-1-5x plus-icon"></i>
                            <cp-header-menu-tooltip class="start-items" :items="startItems" title="Start" />
                        </div>
                        <!-- <div class="hidden-phone">
              <a
                class="btn btn-gradient btn-large"
                href="{{route('user.login', 'create')}}"
              >
                <strong>Join Now</strong><span class="site-header__hidden-when-narrower"></span>
              </a>
            </div> -->
                        <div v-else class="hidden-phone dropdown-tooltip-menu">
                            <div class="how-it-works-btn">
                                <i class="fas fa-info-circle primary-color info-icon"></i>
                                <span class="neutral6-color buttons">How Crowdpac works?</span>
                            </div>
                            <cp-header-menu-tooltip class="how-it-works-items" :items="howWorksItems" />
                        </div>
                    </div>

                    <div v-if="path !== '/user/login'" id="js-btn-site-search-container"
                        class="site-header__hidden-when-phone">
                        <cp-site-search-toggle-button
                            class=" inline-button site-header__main-nav-item site-header__main-nav-item--icon-only">
                            <i class="fas fa-search fa-1-5x" title="Site search"></i>
                        </cp-site-search-toggle-button>
                    </div>

                    <div v-if="isUserLogin">
                        <a href="/user/notifications"
                            class="site-header__main-nav-item site-header__main-nav-item--icon-only site-header__notifications-icon-container mp-link no-underline"
                            data-mp-action="click-notif-icon.coms">
                            <i class="js-sitewide-replace-attr-user-notifications-count fas fa-bell fa-1-5x site-header__notifications-icon neutral5-color"
                                data-user-notifications-count="0"></i>
                            <span
                                class="js-sitewide-replace-user-notifications-count js-sitewide-replace-attr-user-notifications-count site-header__notifications-count"
                                data-user-notifications-count="0"></span>
                        </a>
                    </div>

                    <cp-site-chat-notification v-if="isUserLogin" url="/user/chats" />

                    <div v-if="!isUserLogin" style="display: flex; align-items: center;">
                        <div class="site-header__main-nav-item site-header__main-nav-item--icon-only hidden-phone">
                            <a href="/user/login?is_create=true" class="btn btn-primary sign-up-btn">
                                <span class="buttons white-color">Sign up</span>
                            </a>
                            <a href="/user/login" class="btn btn-primary log-in-btn">
                                <span class="buttons primary-color">Log in</span>
                            </a>
                        </div>
                        <div class="visible-phone dropdown-tooltip-menu">
                            <i class="fas fa-info-circle primary-color fa-1-5x info-icon-btn"></i>
                            <cp-header-menu-tooltip class="how-it-works-items-mobile" :items="howWorksItems" />
                        </div>
                        <a href="/user/login"
                            class="user-icon-container site-header__main-nav-item site-header__main-nav-item--icon-only visible-phone">
                            <i class="fal fa-user"></i>
                        </a>
                    </div>

                    <div v-if="isUserLogin" class="dropdown">
                        <a class="site-header__main-nav-item site-header__main-nav-item--icon-only site-header__main-nav-avatar"
                            data-toggle-X="dropdown">
                            <img :src="userLoginData.img" class="js-sitewide-replace-user-img site-header__user-image"
                                width="40" height="40" alt="Profile image" />
                        </a>

                        <ul class="dropdown-menu site-header__nav-menu pull-right">
                            <li>
                                <a class="site-header__nav-user-profile" href="/user/profile">
                                    <img :src="userLoginData.img"
                                        class="js-sitewide-replace-user-img site-header__nav-user-profile-image"
                                        width="40" height="40" alt="Profile image" />

                                    <div>
                                        <div class="js-sitewide-replace-user-name site-header__nav-user-profile-name">My
                                            Account</div>
                                        <div class="site-header__nav-user-profile-meta">View profile</div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/user/my-activity"
                                    :class="{ 'site-header__nav-menu-item': true, 'site-header__nav-menu-item--active': path == '/user/campaigns' }">
                                    <div class="site-header__nav-menu-item-heading">
                                        My activity
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/user/settings"
                                    :class="{ 'site-header__nav-menu-item': true, 'site-header__nav-menu-item--active': path.startsWith('/user/settings') || path.startsWith('/user/emails') }">
                                    <div class="site-header__nav-menu-item-heading">
                                        Settings
                                    </div>
                                </a>
                            </li>
                            <li>
                                <form method="POST" action="/user/logout">
                                    <button @click="handleLogout" type="submit"
                                        class="inline-button site-header__nav-menu-item">
                                        <div class="site-header__nav-menu-item-heading">Sign out</div>
                                    </button>
                                </form>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>

            <div class="js-main-nav-mobile-parent"></div>

            <!-- mobile nav -->
            <div class="mobile-menu-shadow shadow-left" id="js-mobile-nav-shadow"></div>
            <div v-if="isOpenhamburguerMenu" class="mobile-menu-overlay">
                <!-- took this out for the time being
			because it made the nav too big
			on small phones -->
                <div class="mobile-nav__header" style="display:none;">
                    <span>Menu</span>
                </div>

                <div class="mobile-nav__top">
                    <ul>
                        <li>
                            <img src="https://assets.crowdpac.com/v2/production/all/img/crowdpac-logo.png" class="site-header__logo-sidebar" alt="Crowdpac">
                        </li>

                        <li>
                            <a class="no-underline" href="/">
                                <span class="mobile-nav__link-text">Overview</span>
                            </a>
                        </li>
                        <li>
                            <a class="no-underline" href="/feed#news">
                                <span class="mobile-nav__link-text">News Feed</span>
                            </a>
                        </li>
                        <li>
                            <a class="no-underline" href="/feed#tv">
                                <span class="mobile-nav__link-text">Crowdpac TV</span>
                            </a>
                        </li>
                        <!-- <li>
					<a class="no-underline" href="{{route('social-feed.index')}}">
						<span class="mobile-nav__link-text">Home</span>
					</a>
				</li>

				<li>
					<a class="no-underline" href="{{route('crowdpac.learn')}}">
						<span class="mobile-nav__link-text">How It Works</span>
					</a>
				</li>
				<li>
					<a class="no-underline" href="{{route('user.crowdpacs.create')}}">
						<span class="mobile-nav__link-text">Start a Campaign</span>
					</a>
				</li> -->
                        <!-- <li>
          <a class="btn btn-primary" href="{{route('user.login', 'create')}}">
            <span class="mobile-nav__link-text" style="color:white;">Join Now</span>
          </a>
        </li> -->
                    </ul>
                    <hr>
                    <div>
                        <h3
                            style="font-family: Proxima Nova; font-size: 17px; font-style: normal; font-weight: 700; line-height: 20px; color: #555">
                            Download the app</h3>
                        <a style="text-decoration: none; padding: 0; padding-bottom: 14px;" :href="iosApp"
                            target="_blank" rel="noopener noreferrer">
                            <img src="https://assets.crowdpac.com/v2/production/all/img/app-store.svg" alt="App Store image">
                        </a>
                        <a style="text-decoration: none; padding: 0;" :href="androidApp" target="_blank"
                            rel="noopener noreferrer">
                            <img src="https://assets.crowdpac.com/v2/production/all/img/google-play.svg" alt="Google Play image">
                        </a>
                    </div>
                    <hr>
                    <ul>
                        <h4 style="color:#333;">Legal</h4>
                        <li><a style="color:#666;" href="{!!route('page.privacy')!!}">Privacy Policy</a></li>
                        <li><a style="color:#666;" href="{!!route('page.terms')!!}">Terms Of Use</a></li>
                    </ul>
                </div>
            </div>

            <!-- mobile add menu -->
            <div class="mobile-menu-shadow shadow-right" id="js-mobile-add-shadow"></div>
            <cp-header-menu-tooltip class="start-items " :items="startItems" title="Start" />
            <cp-site-search />
        </div>
    </div>
</template>

<style scoped></style>