<!--
    This component responds to clicks by copying its `text` prop to the clipboard.
    It doesn't care what its contents are, but a button of some kind is probably a good idea.

    You can customize the confirmation message by inserting a <template #confirmation> element.
-->

<style>
    .copy-to-clipboard-trigger {
        position: relative;
    }

    .copy-to-clipboard-trigger__confirmation {
        background: green;
        border-radius: 3px;
        bottom: 100%;
        color: white;
        left: 50%;
        padding: 0.1em 1em;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.5s, transform 0.5s;
    }

    .copy-to-clipboard-trigger__confirmation:not([data-confirmed]) {
        opacity: 0;
        transform: translate(-50%, 100%);
    }

    .copy-to-clipboard-trigger__confirmation[data-confirmed="in"] {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    .copy-to-clipboard-trigger__confirmation[data-confirmed="out"] {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
</style>

<template>
    <span class="copy-to-clipboard-trigger" @click="handleClick">
        <slot />

        <span class="copy-to-clipboard-trigger__confirmation" :data-confirmed="confirmed">
            <template v-if="$slots.confirmation">
                <slot name="confirmation"></slot>
            </template>
            <span v-else>Copied!</span>
        </span>
    </span>
</template>

<script>
    export default {
        props: {
            text: String,
            confirmationDelay: {type: Number, default: 1000},
        },

        data() {
            return {
                confirmed: null,
                confirmationTimeout: null,
            };
        },

        methods: {
            handleClick(event) {
                event.preventDefault();

                const copied = this.copyText(this.text);

                if (copied) {
                    this.showConfirmation();
                } else {
                    prompt('Copy the following text.', this.text);
                }
            },

            showConfirmation() {
                this.confirmed = 'in';

                clearTimeout(this.confirmationTimeout);
                this.confirmationTimeout = setTimeout(() => {
                    this.confirmed = 'out';

                    this.confirmationTimeout = setTimeout(() => {
                        this.confirmed = null;
                    }, this.confirmationDelay / 2);
                }, this.confirmationDelay);
            },

            copyText(textToCopy) {
                const input = document.createElement('textarea');
                input.style.opacity = 0;
                input.style.position = 'fixed';
                input.value = textToCopy;
                this.$el.parentNode.insertBefore(input, this.$el);

                const isMobileSafari = navigator.userAgent.match(/ipad|ipod|iphone/i);
                if (isMobileSafari) {
                    // iOS compatibility courtesy of https://stackoverflow.com/a/34046084.
                    input.contentEditable = true;
                    input.readOnly = true;

                    const range = document.createRange();
                    range.selectNodeContents(input);

                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);

                    input.setSelectionRange(0, textToCopy.length);
                } else {
                    input.select();
                }

                const copied = document.execCommand('copy');

                input.parentNode.removeChild(input);

                return copied;
            },
        },
    };
</script>
