<style>
.tooltip__underlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    z-index: 10; /* TODO: Look into using portals to insert this as the last child of the body. */
}

.tooltip__underlay:not([data-modal]) {
    bottom: auto;
    height: 0;
}

.tooltip__container {
    max-width: 80vw;
    position: absolute;
    transition: opacity 0.3s, transform 0.1s;
    width: -webkit-max-content; /* What's up, postcss? */
    width: max-content;
}

.tooltip:not([data-unstyled]) {
    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    color: white;
    padding: 0.1px 0;
    transform: translateY(1em);
}

:not([data-unstyled]) > .tooltip__pointer {
    border: 0.5em solid rgba(0, 0, 0, 0.9);
    border-bottom-color: transparent;
    border-right-color: transparent;
    bottom: calc(100% - 1px);
    position: absolute;
    /* The z-translation works around an antialiasing issue in Firefox (https://stackoverflow.com/a/20957574). */
    transform: translate3d(-50%, 50%, 1px) rotate(45deg);
    transform-origin: center center;
}

:not([data-unstyled]) > .tooltip__content {
    margin: var(--gutter);
}

.tooltip--thin .tooltip__content {
    margin: 10px;
}

.tooltip--light .tooltip {
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 1px #000;
}

.tooltip--light .tooltip__pointer {
    border-top-color: #fff;
    border-left-color: #fff;
    box-shadow: -1px -1px 0px #aaa;
}
</style>

<template>
    <div :id="id" v-if="isOpen" class="tooltip__underlay" :class="className" :data-modal="modal" :data-ignore-hash-change="ignoreHashChange" @click="handleBackdropClick">
        <div class="tooltip__container" :style="styles.container">
            <div ref="tooltip" class="tooltip" :data-unstyled="unstyled">
                <div class="tooltip__pointer" :style="styles.pointer"></div>
                <div class="tooltip__content">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {required: true},
            light: Boolean,
            thin: Boolean,
            unstyled: Boolean,
            ignoreHashChange: Boolean,
        },

        data() {
            return {
                tooltipWidth: 0,
            };
        },

        computed: {
            className() {
                return {
                    'tooltip--light': this.light,
                    'tooltip--thin': this.thin,
                };
            },

            isOpen() {
                return this.$root.openTooltips[this.id];
            },

            modal() {
                return this.$root.openTooltips[this.id].modal;
            },

            styles() {
                if (!this.isOpen) return {};

                const {toggle, toggleCoords} = this.$root.openTooltips[this.id];
                const {left: toggleLeft, top: toggleTop, width: toggleWidth, height: toggleHeight} = toggleCoords;

                const root = toggle.closest('.page-nav-bar') || toggle.closest('.page-content') || this.$root.$el;
                const {left: rootLeft, width: rootWidth} = root.getBoundingClientRect();

                const toggleOffsetFromRoot = (toggleLeft - rootLeft) / (rootWidth - toggleWidth);
                const tooltipLeftFromRoot = rootLeft + (rootWidth - this.tooltipWidth) * toggleOffsetFromRoot;
                const pointerLeftFromTooltip = this.tooltipWidth * toggleOffsetFromRoot + toggleWidth * (toggleOffsetFromRoot - 0.5) * -1;

                return {
                    container: {
                        left: `${tooltipLeftFromRoot}px`,
                        opacity: this.tooltipWidth === 0 ? 0 : 1, // Wait until it's got a size.
                        top: `${toggleTop + toggleHeight}px`,
                        transform: `translateY(${this.tooltipWidth === 0 ? '-10px' : 0})`, // Slide in a bit.
                    },

                    pointer: {
                        left: `${pointerLeftFromTooltip}px`,
                    },
                };
            },
        },

        methods: {
            handleBackdropClick(event) {
                if (this.$el.querySelectorAll) {
                    const tipDescendants = this.$el.querySelectorAll('*');
                    if (Array.prototype.indexOf.call(tipDescendants, event.target) !== -1) return;
                    this.$root.hideTooltip(this.id);
                }
            }
        },

        watch: {
            async isOpen(isOpen) {
                if (isOpen) {
                    // Wait for e.g. shuttles to move around.
                    setTimeout(() => {
                        if (this.$refs.tooltip) {
                            this.tooltipWidth = this.$refs.tooltip.getBoundingClientRect().width;
                        }
                    }, 10);
                } else {
                    this.tooltipWidth = 0;
                }
            },
        },

        beforeDestroy() {
            this.$root.hideTooltip(this.id);
        },

        rootMixin: {
            data() {
                return {
                    openTooltips: {}
                };
            },

            methods: {
                handleViewportChanges() {
                    Object.keys(this.openTooltips).forEach((id) => {
                        const {toggle} = this.openTooltips[id];
                        const toggleCoords = toggle.getBoundingClientRect();
                        this.openTooltips[id].toggleCoords = toggleCoords;
                    });
                },

                handleHashChange() {
                    // TODO: Refactor tooltip handling. We shouldn't be digging around in the DOM like this.
                    const tooltipIds = Object.keys(this.openTooltips);
                    const tooltipElements = tooltipIds.map(id => document.getElementById(id)).filter(Boolean);
                    const tooltipsElementsToClose = tooltipElements.filter(element => !element.dataset.ignoreHashChange);
                    const tooltipIdsToClose = tooltipsElementsToClose.map(element => element.id);
                    tooltipIdsToClose.forEach(this.hideTooltip);
                },

                showTooltip(id, toggle, modal) {
                    const toggleCoords = toggle.getBoundingClientRect();
                    this.$set(this.openTooltips, id, {toggle, toggleCoords, modal});
                    this.updateBodyCursorForNonModalTooltips();
                },

                hideTooltip(id) {
                    this.$delete(this.openTooltips, id);
                    this.updateBodyCursorForNonModalTooltips();
                },

                updateBodyCursorForNonModalTooltips() {
                    // Safari fires a mouseleave event when tapping away from an element only if the cursor is a pointer.
                    const anyNonModalTooltip = Object.keys(this.openTooltips).find(id => !this.openTooltips[id].modal);
                    document.body.style.cursor = anyNonModalTooltip ? 'pointer' : '';
                }
            },

            created() {
                addEventListener('resize', this.handleViewportChanges);
                addEventListener('scroll', this.handleViewportChanges);
                addEventListener('hashchange', this.handleHashChange);
            },

            beforeDestroy() {
                removeEventListener('resize', this.handleViewportChanges);
                removeEventListener('scroll', this.handleViewportChanges);
                removeEventListener('hashchange', this.handleHashChange);
            },
        },
    };

</script>
