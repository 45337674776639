import * as firebase from 'firebase';

var config = {
	authDomain: process.env.MIX_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.MIX_FIREBASE_DATABASE_URL,
	projectId: process.env.MIX_FIREBASE_PROJECT_ID,
	storageBucket: process.env.MIX_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.MIX_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.MIX_FIREBASE_APP_ID,
	measurementId: process.env.MIX_FIREBASE_MEASUREMENT_ID
};

const fire = firebase.initializeApp(config);
export default fire;
