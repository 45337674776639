import { defineStore } from 'pinia';

export const useHeaderStore = defineStore('header', {
  state: () => {
    return {
      sitewideBannerInfo: { visible: false, height: 0 },
    };
  },
  getters: {
    getSitewideBannerInfo: state => state.sitewideBannerInfo,
  },
  actions: {
    setSitewideBannerInfo(height) {
      this.sitewideBannerInfo = { visible: true, height };
    },
  },
});
