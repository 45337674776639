<script>
  export default{
    name : 'SidebarMenuTvIcon',
  }
</script>

<template>
  <g>
    <path d="M26.3887 8.75H5.27756C3.82014 8.75 2.63867 9.86929 2.63867 11.25V25C2.63867 26.3807 3.82014 27.5 5.27756 27.5H26.3887C27.8461 27.5 29.0276 26.3807 29.0276 25V11.25C29.0276 9.86929 27.8461 8.75 26.3887 8.75Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.4308 2.5L15.8335 8.75L9.23633 2.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>
