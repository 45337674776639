import Vue from "vue";

export default new Vue({
    data() {
        return {
            showing: false,
        };
    },

    methods: {
        toggle() {
            this.showing = !this.showing;
        },

        dismiss() {
            this.showing = false;
        }
    },

    watch: {
        showing(showing) {
            dispatchEvent(new Event(showing ? "sitesearchshow" : "sitesearchhide"));
        }
    },

    created() {
        addEventListener("dismisssitesearch", this.dismiss);
    },
});
