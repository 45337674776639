// This nasty little hack prevents iOS Safari from putting a hover state on an element
// after the element directly above it has been clicked.
// E.g. After a dialog's close box was clicked,
// an unrelated button under the close box (now under the user's finger) would get a hover state.

export default function preventExcessiveHoverStates() {
  addEventListener('click', function() {
    setTimeout(() => {
      document.documentElement.style.pointerEvents = 'none';
      setTimeout(() => {
        document.documentElement.style.pointerEvents = '';
      }, 50);
    });
  });
}
