var SiteHeader = {
    slideSpeed: 'fast',
    _currentlyMobile: false,

    // Events from "site-header/search/common"
    siteSearchEvents: {
        SHOW: 'sitesearchshow',
        HIDE: 'sitesearchhide',
        DISMISS: 'dismisssitesearch'
    },

    init: function() {
        SiteHeader.bindEvents();
    },

    bindEvents: function() {
        SiteHeader.handleResize();

        $(window).on('resize', SiteHeader.handleResize);
        $(window).on(SiteHeader.siteSearchEvents.SHOW, SiteHeader.handleSiteSearchShow);
        $(window).on(SiteHeader.siteSearchEvents.HIDE, SiteHeader.handleSiteSearchHide);
        $('#js-mobile-nav-shadow').on('click', SiteHeader.closeMobileNav);
        $('#js-close-user-menu').on('click', SiteHeader.closeUserMenu);
        $('#js-mobile-user-shadow').on('click', SiteHeader.closeUserMenu);
        $('#js-mobile-add-shadow').on('click', SiteHeader.closeAddMenu);
        $('#js-site-header')
            .on('click', '#js-mobile-user-icon', SiteHeader.toggleUserMenu)
            .on('click', '#js-mobile-add-icon', SiteHeader.toggleAddMenu)
            .on('click', '#js-mobile-menu-toggle', SiteHeader.toggleMobileNav);
    },

    handleResize: function() {
        // TODO: Debounce.
        var shouldBeMobile = innerWidth <= 767;
        if (!SiteHeader._currentlyMobile && shouldBeMobile) {
            SiteHeader.switchToMobileLayout();
        } else if (SiteHeader._currentlyMobile && !shouldBeMobile) {
            SiteHeader.switchToNormalLayout();
        }
    },

    switchToMobileLayout: function() {
        var $mainNav = $('#js-main-nav');
        $mainNav.css('display', 'none');
        SiteHeader.closeSearch();
        SiteHeader._currentlyMobile = true;
    },

    switchToNormalLayout: function() {
        var $mainNav = $('#js-main-nav');
        $mainNav.css('display', '');
        SiteHeader.closeSearch();
        SiteHeader._currentlyMobile = false;
    },

    toggleMobileNav: function() {
        var overlay = $('#js-main-nav-mobile');
        var shadow = $('#js-mobile-nav-shadow');
        var menuToggle = $('.mobile-menu-toggle');

        if (overlay.hasClass('mobile-menu--open')) {
            overlay.removeClass('mobile-menu--open');
            menuToggle.removeClass('open');
            shadow.removeClass('mobile-menu-shadow--open');
            $('body').removeClass('lock-screen');
        } else {
            SiteHeader.closeUserMenu();
            SiteHeader.closeSearch();
            overlay.addClass('mobile-menu--open');
            menuToggle.addClass('open');
            shadow.addClass('mobile-menu-shadow--open');
            $('body').addClass('lock-screen');
        }
    },

    closeMobileNav: function() {
        var overlay = $('#js-main-nav-mobile');
        var shadow = $('#js-mobile-nav-shadow');
        var menuToggle = $('.mobile-menu-toggle');

        overlay.removeClass('mobile-menu--open');
        menuToggle.removeClass('open');
        shadow.removeClass('mobile-menu-shadow--open');
        $('body').removeClass('lock-screen');
    },

    handleSiteSearchShow: function() {
        var menuToggle = $('#js-mobile-menu-toggle');

        if (SiteHeader._currentlyMobile) {
            SiteHeader.closeUserMenu();
            SiteHeader.closeMobileNav();
            menuToggle.removeClass('open');
        }

        if (SiteHeader._currentlyMobile) {
            $('body').addClass('lock-screen');
        }
    },

    handleSiteSearchHide: function() {
        if (SiteHeader._currentlyMobile) {
            $('body').removeClass('lock-screen');
        }
    },

    closeSearch: function() {
        dispatchEvent(new Event(SiteHeader.siteSearchEvents.DISMISS));
    },

    toggleUserMenu: function() {
        SiteHeader.closeMobileNav();
        SiteHeader.closeSearch();

        var $shadow = $('#js-mobile-user-shadow');
        var $menu = $('#js-mobile-user-menu');

        if ($menu.hasClass('mobile-menu--open')) {
            $menu.removeClass('mobile-menu--open');
            $shadow.removeClass('mobile-menu-shadow--open');
            $('body').removeClass('lock-screen');
        } else {
            $menu.addClass('mobile-menu--open');
            $shadow.addClass('mobile-menu-shadow--open');
            $('body').addClass('lock-screen');
        }
    },

    closeUserMenu: function() {
        $('#js-mobile-user-menu').removeClass('mobile-menu--open');
        $('#js-mobile-user-shadow').removeClass('mobile-menu-shadow--open');
        $('body').removeClass('lock-screen');
    },

    toggleAddMenu: function() {
        SiteHeader.closeMobileNav();
        SiteHeader.closeSearch();

        var $shadow = $('#js-mobile-add-shadow');
        var $menu = $('#js-mobile-add-menu');

        if ($menu.hasClass('mobile-menu--open')) {
            $menu.removeClass('mobile-menu--open');
            $shadow.removeClass('mobile-menu-shadow--open');
            $('body').removeClass('lock-screen');
        } else {
            $menu.addClass('mobile-menu--open');
            $shadow.addClass('mobile-menu-shadow--open');
            $('body').addClass('lock-screen');
        }
    },

    closeAddMenu: function() {
        $('#js-mobile-add-menu').removeClass('mobile-menu--open');
        $('#js-mobile-add-shadow').removeClass('mobile-menu-shadow--open');
        $('body').removeClass('lock-screen');
    }
};

SiteHeader.init();
