// LaravelMixHack.vue
<template>
    <div></div>
</template>

<script>
export default {
    name: 'LaravelMixHack',
};

</script>

<style>
div#nonExistingSCSSIdHack {
    display: none;
}
</style>
