<template>
    <button type="button" class="site-search-button" @click="common.toggle">
        <slot></slot>
    </button>
</template>

<script>
import common from "./common";

export default {
    data() {
        return {
            common
        };
    }
};
</script>
