import algoliasearch from 'algoliasearch/lite';

const applicationId = process.env.MIX_ALGOLIA_ID;

let publicKey = process.env.MIX_ALGOLIA_PUBLIC_KEY_STAGING;
let indexPrefix = 'staging_';

if (location.hostname.startsWith('www.')) {
    publicKey = process.env.MIX_ALGOLIA_PUBLIC_KEY_PRODUCTION;
    indexPrefix = 'production_';
}

const algoliaClient = algoliasearch(applicationId, publicKey);

const algoliaIndices = {}; // These are created on-the-fly as needed.

export function buildSimpleFiltersString(filters) {
    // { a: 'b', c: ['d', 'e'] } becomes "a:b AND (c:d OR c:e)"
    return Object.entries(filters)
        .reduce((keyValues, [key, values]) => {
            const allValues = [].concat(values);
            const pairs = allValues
                .reduce((results, value) => {
                    if (value || value === 0) {
                        const quote = typeof value === 'string' && value.includes(`"`) ? `'` : `"`;
                        results.push(`${key}:${quote}${value}${quote}`);
                    }

                    return results;
                }, [])
                .join(' OR ');

            if (allValues.length !== 0) {
                keyValues.push(allValues.length === 1 ? pairs : `(${pairs})`);
            }

            return keyValues;
        }, [])
        .join(' AND ');
}

// Documented at https://www.algolia.com/doc/api-reference/api-parameters/
const DEFAULT_SEARCH_PARAMS = {
    query: '',
    filters: '',
    page: 0,
    hitsPerPage: 24,
    highlightPreTag: '<em class="algolia-search-highlight">',
    highlightPostTag: '</em>'
};

export const GLOBAL_SEARCH_INDEXES = {
    crowdpacs: 0,
    candidates: 1,
    petitions: 2,
    news: 3
};

export default async function algoliaSearch(index, customParams) {
    let algoliaIndex = algoliaIndices[index];
    if (algoliaIndex === undefined) {
        algoliaIndex = algoliaClient.initIndex(indexPrefix + index);
        algoliaIndices[index] = algoliaIndex;
    }

    const searchParams = Object.assign({}, DEFAULT_SEARCH_PARAMS, customParams);

    return new Promise((resolve, reject) => {
        algoliaIndex.search(searchParams, function(error, result) {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });
}

export async function algoliaGlobalSearch(params) {
    return new Promise((resolve, reject) => {
        algoliaClient.search(buildRequestQueries(params), function(error, result) {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });
}

function buildRequestQueries(params) {
    return Object.keys(GLOBAL_SEARCH_INDEXES).map(index => {
        return {
            indexName: algoliaClient.initIndex(indexPrefix + index).indexName,
            query: params.query,
            params: {
                hitsPerPage: params.pageSize,
                page: params[index].page
            }
        };
    });
}
