<!--
    This simply implements ButtonBase as an <a> link, check out the docs there!
-->

<script>
    import ButtonBase from './button-base.vue';

    export default {
        extends: ButtonBase,

        props: {
            disabled: Boolean,
        },

        template: `
            <a v-bind="$attrs" :class="className" :data-disabled="disabled" @click="handleClick">
                ${ButtonBase.templateContent}
            </a>
        `,

        methods: {
            handleClick(event) {
                if (this.disabled) {
                    event.preventDefault();
                }
            },
        },
    };
</script>
