import axios from 'axios';

export default {
    data: {
        userSubscriptions: {},
        pendingUserSubscriptions: {}
    },

    // TODO: Error handling!

    methods: {
        getUserSubscription(subscriptionType, subscriptionTypeId, triggerSubscribe) {
            const key = subscriptionType + ':' + subscriptionTypeId;
            if (key in this.pendingUserSubscriptions) {
                return;
            }

            if (triggerSubscribe) {
                this.createUserSubscription(subscriptionType, subscriptionTypeId);
                return;
            }

            this.$set(this.pendingUserSubscriptions, key, true);

            // Ask the API
            axios.get('/apiv2/user/subscriptions/check?type=' + subscriptionType + '&type_id=' + subscriptionTypeId)
                .then((response) => {
                    this.$set(this.userSubscriptions, key, response.data.data.is_subscribed);
                    this.$delete(this.pendingUserSubscriptions, key);
                }).catch((error) => {
                    this.$delete(this.pendingUserSubscriptions, key);
                });
        },

        createUserSubscription(subscriptionType, subscriptionTypeId) {
            const key = subscriptionType + ':' + subscriptionTypeId;
            this.$set(this.pendingUserSubscriptions, key, true);

            // Send to the API
            axios.post('/apiv2/user/subscriptions/create',
                {'type': subscriptionType, 'type_id': subscriptionTypeId})
                .then((response) => {
                    this.$set(this.userSubscriptions, key, true);
                    this.$delete(this.pendingUserSubscriptions, key);
                }).catch((error) => {
                    // weak error handling
                    if (error.response.data.error == 'already_subscribed') {
                        this.$set(this.userSubscriptions, key, true);
                        this.$delete(this.pendingUserSubscriptions, key);
                    } else {
                        this.$set(this.userSubscriptions, key, false);
                        this.$delete(this.pendingUserSubscriptions, key);
                    }
                });
        },

        deleteUserSubscription(subscriptionType, subscriptionTypeId) {
            const key = subscriptionType + ':' + subscriptionTypeId;
            this.$set(this.pendingUserSubscriptions, key, true);

            // Send to the API
            axios.post('/apiv2/user/subscriptions/delete',
                {'type': subscriptionType, 'type_id': subscriptionTypeId})
                .then((response) => {
                    this.$set(this.userSubscriptions, key, false);
                    this.$delete(this.pendingUserSubscriptions, key);
                }).catch((error) => {
                    this.$set(this.userSubscriptions, key, false);
                    this.$delete(this.pendingUserSubscriptions, key);
                });
        }
    }
};