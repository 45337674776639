<script>
import Vue from 'vue';
import VueSocialSharing from 'vue-social-sharing';
import ShareNetwork from 'vue-social-sharing/src/share-network';

export default {
    components: {
        ShareNetwork,
        CpCopyToClipboardTrigger: require('../copy-to-clipboard-trigger').default,
        CpButton: require('../button').default,
        CpButtonLink: require('../button-link').default,
        CpDialog: require('../dialog').default,
        CpBoxContent: require('../box-content').default,
        CpBox: require('../box').default,
        PersonAddIcon: require('../social-feed/icons/person-add').default,
    },
    directives: {
        margin: require('../directives/margins').margin,
        marginRight: require('../directives/margins').marginRight,
    },
    props: {
        url: String,
        title: String,
        description: String,
        quote: String,
        hashtags: String,
        twitterUser: String,
        invitingUserId: String,
    },
    name: 'CpInviteMembersDialog',
    methods: {
        inviteUrl(network) {
            return this.invitingUserId === null
                ? this.url + '?ref_code=' + network.network + '-share&inviting_user_id=0'
                : this.url + '?ref_code=' + network.network + '-share&inviting_user_id=' + this.invitingUserId;
        },
        handleButtonClick() {
            this.showDialog = !this.showDialog;
            this.$emit('inviteButtonClicked');
        },
    },
    computed: {
        emailInviteUrl() {
            return this.invitingUserId === null
                ? `mailto:?subject=${this.title}&body=${this.url}?ref_code=email-share&inviting_user_id=0 ${this.description}`
                : `mailto:?subject=${this.title}&body=${this.url}?ref_code=email-share&inviting_user_id=${this.invitingUserId} ${this.description}`;
        },
        smsInviteUrl() {
            return this.invitingUserId === null
                ? `sms://&body=${this.url}?ref_code=sms-share ${this.description}`
                : `sms://&body=${this.url}?inviting_user_id=${this.invitingUserId} ${this.description}`;
        },
    },
    data() {
        return {
            showDialog: false,
            networks: [
                { network: 'twitter', name: 'X (Twitter)', image: 'https://assets.crowdpac.com/v2/production/all/img/twitter-x-icon.svg' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fa-facebook fa-2x facebook-color' },
                { network: 'linkedin', name: 'LinkedIn', icon: 'fab fa-linkedin fa-2x linkedin-color' },
                { network: 'reddit', name: 'Reddit', icon: 'fab fa-reddit-alien fa-2x reddit-color' },
                { network: 'flipboard', name: 'Flipboard', icon: 'fab fa-flipboard fa-2x flipboard-color' },
                { network: 'pinterest', name: 'Pinterest', icon: 'fab fa-pinterest fa-2x pinterest-color' },
                { network: 'quora', name: 'Quora', icon: 'fab fa-quora fa-2x quora-color' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fa-whatsapp fa-2x whatsapp-color' },
            ],
        };
    },
};
</script>
<template>
    <div class="invite-members-button-container">
        <cp-button primary @click.prevent="handleButtonClick">
            <span class="button-with-icon-content">
                <person-add-icon v-margin-right="$root.isMobile ? 0 : .5" />
                <span v-bind:class="{ mobile: $root.isMobile, 'share-text invite-member-button-text': true }">Invite Members</span>
            </span>
        </cp-button>
        <br />
        <cp-dialog v-if="showDialog" @dismiss="handleButtonClick()">
            <cp-box>
                <cp-box-content>
                    <section style="margin: 4em 2.5em; max-width: 55ch; text-align: center">
                        <header class="headline black-color">Invite your friends to become crowdpac members with your social networks!</header>
                        <br />
                        <br />
                        <i @click.prevent="handleButtonClick" class="fal fa-times primary-color fa-2x close-icon"></i>
                        <div class="share-network-list">
                            <share-network
                                v-for="network in networks"
                                :network="network.network"
                                :key="network.network"
                                :url="inviteUrl(network)"
                                :title="title"
                                :description="description"
                                :quote="quote"
                                :hashtags="hashtags"
                                :twitterUser="twitterUser"
                            >
                                <img v-if="network.image" :src="network.image" class="twitter-img" />
                                <i v-else :class="network.icon"></i>
                                <p class="footnote neutral4-color" v-margin="0">{{ network.name }}</p>
                            </share-network>
                            <a :href="smsInviteUrl" class="share-network-sms">
                                <i class="fal fa-comment-dots fa-2x black-color"></i>
                                <p class="footnote neutral4-color" v-margin="0">SMS</p>
                            </a>
                            <a :href="emailInviteUrl" class="share-network-email">
                                <i class="fal fa-envelope fa-2x black-color"></i>
                                <p class="footnote neutral4-color" v-margin="0">Email</p>
                            </a>
                            <cp-copy-to-clipboard-trigger :text="url">
                                <a>
                                    <div class="icon-wrapper">
                                        <img src="https://assets.crowdpac.com/v2/production/all/img/instagram-icon.svg" />
                                        <p class="footnote neutral4-color" v-margin="0">Instagram</p>
                                    </div>
                                </a>
                            </cp-copy-to-clipboard-trigger>
                            <cp-copy-to-clipboard-trigger :text="url">
                                <a>
                                    <div class="icon-wrapper">
                                        <i class="fa fa-home fa-2x home-color"></i>
                                        <p class="footnote neutral4-color" v-margin="0">Nextdoor</p>
                                    </div>
                                </a>
                            </cp-copy-to-clipboard-trigger>
                        </div>
                    </section>
                </cp-box-content>
            </cp-box>
        </cp-dialog>
    </div>
</template>
<style scoped>
.invite-members-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 400px;
    margin: auto;
}
a[class^='share-network-'] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 58px;
    height: 54px;
    border: 1px solid var(--neutral5);
    border-radius: 16px;
    padding: 8px;
    box-shadow: 0px 2px 5px 0px #0000001A;
    background-color: var(--white);
    cursor: pointer;
    margin: 5px;
}
a:hover {
    text-decoration: none;
    opacity: 0.85;
}

span.invite-member-button-text.mobile {
    display: none;
}

a[class^='share-network-'] .fah {
    padding: 10px 10px 5px 10px;
    flex: 0 1 auto;
    margin: 0 auto;
    font-size: 32px;
    line-height: 100%;
}
.icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 58px;
    height: 54px;
    border: 1px solid var(--neutral5);
    border-radius: 16px;
    padding: 8px;
    box-shadow: 0px 2px 5px 0px #0000001A;
    background-color: var(--white);
    cursor: pointer;
    margin: 5px;
}

a[class^='share-network-'] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
}
svg.share-svg-icon {
    height: 1em;
    width: 1em;
}
svg.close-svg-icon {
    float: right;
    top: 5px;
    right: 5px;
    position: absolute;
    height: 2.5em;
    width: 2.5em;
}
div.feed svg {
    color: white !important;
    height: 1.5em !important;
    width: 1.5em !important;
}
div.campaign {
    margin: 0px;
    line-height: inherit;
    margin-right: 15px;
}
span.button-with-icon-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
div.mobile.petition .outside {
    /*modal is off center for petition share */
    -webkit-transform: translate(-56%, -50%);
    transform: translate(-56%, -50%);
}
.close-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.twitter-img {
    width: 2em;
}

@media screen and (max-width: 768px) {
    .invite-members-button-container {
        align-items: center;
    }
}
</style>
