<style>
.generic-reveally-thing:not([data-fully-expanded]) {
    overflow: hidden;
}
</style>

<template>
    <transition @enter="expand" @leave="collapse">
        <div class="generic-reveally-thing" :data-fully-expanded="fullyExpanded">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        duration: { type: Number, default: 500 }
    },

    data() {
        return {
            fullyExpanded: false
        };
    },

    computed: {
        transitionValue() {
            return `height ${this.duration}ms, margin-top ${this.duration}ms, opacity ${this.duration}ms`;
        }
    },

    methods: {
        getMarginOffset() {
            // Handle collapsing margins on siblings and parent.
            const previous = this.$el.previousElementSibling
                ? getComputedStyle(this.$el.previousElementSibling).marginBottom
                : getComputedStyle(this.$el.parentElement).marginTop;
            const next = this.$el.nextElementSibling
                ? getComputedStyle(this.$el.nextElementSibling).marginTop
                : getComputedStyle(this.$el.parentElement).marginBottom;
            return `-${parseFloat(previous) < parseFloat(next) ? previous : next}`;
        },

        expand(element, done) {
            element.style.transition = '';
            element.style.height = 0;
            element.style.marginTop = this.getMarginOffset();
            element.style.opacity = 0;

            setTimeout(() => {
                element.style.transition = this.transitionValue;
                element.style.height = `${element.scrollHeight}px`;
                element.style.marginTop = 0;
                element.style.opacity = 1;
            });

            setTimeout(() => {
                element.style.transition = '';
                element.style.height = '';
                element.style.marginTop = '';
                element.style.opacity = '';
                done();
                this.fullyExpanded = true;
            }, this.duration);
        },

        collapse(element, done) {
            this.fullyExpanded = false;
            element.style.transition = '';
            element.style.height = `${element.scrollHeight}px`;
            element.style.marginTop = 0;
            element.style.opacity = 1;

            setTimeout(() => {
                element.style.transition = this.transitionValue;
                element.style.height = 0;
                element.style.marginTop = this.getMarginOffset();
                element.style.opacity = 0;
            });

            setTimeout(() => {
                element.style.transition = '';
                element.style.height = '';
                element.style.marginTop = '';
                element.style.opacity = '';
                done();
            }, this.duration);
        }
    }
};
</script>
