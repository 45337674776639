import axios from 'axios';
import flattenObject from '../lib/flatten-object';

export default async function request(method, endpoint, payload) {
    try {
        let actualPayload = payload;

        if (method === 'get') {
            actualPayload = {
                params: payload
            };
        }

        // `data` is generally all we care about. If you need something else, use Axios directly.
        const response = await axios[method](endpoint, actualPayload);
        return response.data.data;
    } catch (requestError) {
        // Axios throws on failed requests, but not with our messages.
        let errorMessage;

        try {
            if (requestError.response.data.messages) {
                // Bad request:
                const flatMessages = flattenObject(requestError.response.data.messages);
                errorMessage = Object.values(flatMessages).join('\n');
            } else if (requestError.response.data.error && requestError.response.data.error.message) {
                // Problem on the server:
                errorMessage = requestError.response.data.error.message;
            } else {
                errorMessage = `${requestError.response.statusText} (${requestError.response.status})`;
            }
        } catch (messageError) {
            // Just in case we get it back in an unknown format:
            console.error(`Couldn't find an error message for ${method} ${endpoint}`, messageError);
            errorMessage = 'Sorry, there was an error, please try again. Contact support if the problem persists.';
        }

        const error = new Error(errorMessage);
        error.request = requestError.request;
        error.response = requestError.response;
        throw error;
    }
}
