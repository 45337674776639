import qs from "qs";

const DEFAULT_QS_OPTIONS = {
    addQueryPrefix: true,
    arrayFormat: "brackets",
    encodeValuesOnly: true,
    format: "RFC1738",
    ignoreQueryPrefix: true,
    skipNulls: true
};

export default {
    parse(string, options) {
        return qs.parse(string, {
            ...DEFAULT_QS_OPTIONS,
            ...options
        });
    },

    stringify(object, options) {
        return qs.stringify(object, {
            ...DEFAULT_QS_OPTIONS,
            ...options
        });
    }
};
