let handling = false;

const ga = window.ga; // Google Analytics global (production only)

export const CONFIRM_LEAVE = 'confirmleave';
export const UNCONFIRM_LEAVE = 'unconfirmleave';

export default {
    data() {
        return Object.assign({
            locationMixinLeaveConfirmations: [],
        }, this.parseLocation());
    },

    beforeMount() {
        //addEventListener('beforeunload', this._locationMixinHandleUnload);
        addEventListener('hashchange', this._locationMixinHandleHashChange);

        this.$on(CONFIRM_LEAVE, leaveConfirmation => {
            this.locationMixinLeaveConfirmations.push(leaveConfirmation);
        });

        this.$on(UNCONFIRM_LEAVE, leaveConfirmation => {
            const index = this.locationMixinLeaveConfirmations.indexOf(leaveConfirmation);
            if (index !== -1) {
                this.locationMixinLeaveConfirmations.splice(index, 1);
            }
        });
    },

    methods: {
        parseLocation() {
            let [hash, query] = decodeURIComponent(location.hash).split('?');
            hash = hash.slice(1);
            if (query) {
                query = query.split('&').reduce((result, keyValue) => {
                    const [key, value] = keyValue.split('=');
                    result[key] = value;
                    return result;
                }, {});
            }
            return { hash, query };
        },

        async _locationMixinHandleUnload(event) {
            // NOTE: There's not a whole lot we can do here. This text probably won't even display.
            // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
            if (this.locationMixinLeaveConfirmations.length !== 0) {
                const warning = 'This page may have unsaved data, do you really want to leave?';
                event.returnValue = warning;
                return warning;
            }
        },

        async _locationMixinHandleHashChange() {
            if (handling) return;

            handling = true;

            let hashChangePrevented = false;
            for (let i = 0; i < this.locationMixinLeaveConfirmations.length; i += 1) {
                const leaveConfirmed = await this.locationMixinLeaveConfirmations[i]();
                if (!leaveConfirmed) {
                    hashChangePrevented = true;
                    location.hash = this.$root.hash;
                    break;
                }
            }

            if (!hashChangePrevented) {
                const locationProps = this.parseLocation();
                Object.keys(locationProps).forEach(key => this.$set(this, key, locationProps[key]));

                await this.$nextTick();

                const targetElement = document.getElementById(this.hash);
                if (targetElement) {
                    try {
                        targetElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start'
                        });
                    } catch (error) {
                        targetElement.scrollIntoView(true);
                    }
                } else {
                    document.documentElement.scrollTop = 0;
                    document.body.scrollTop = 0;

                    if (ga) {
                        ga('set', 'page', location.pathname + location.search + location.hash);
                        ga('send', 'pageview');
                    }
                }
            }

            setTimeout(() => {
                handling = false;
            });
        },
    },
};
