<!--
    This simply implements ButtonBase as a real <button> element, check out the docs there!
-->

<script>
    import ButtonBase from './button-base.vue';

    export default {
        extends: ButtonBase,

        props: {
            type: {default: 'button'}
        },

        template: `
            <button :type="type" :class="className" @click="handleClick">
                ${ButtonBase.templateContent}
            </button>
        `,

        methods: {
            handleClick(event) {
                this.$emit('click', event);
            }
        }
    };
</script>
