<template>
    <a
        v-bind:href="url"
        class="site-header__main-nav-item site-header__notifications-icon-container mp-link no-underline"
        data-mp-action="click-notif-icon.coms"
    >
        <i class="fas fa-envelope fa-1-5x site-header__notifications-icon neutral5-color"></i>
        <i v-if="newMessages" class="fas fa-circle new-messages-icon primary-color"></i>
    </a>
</template>

<script>
import { mapState } from 'pinia';

import { useUserStore } from '../../../store/useUserStore.js';
import fire from '../firebase';
const db = fire.firestore();
export default {
    name: 'CpSiteChatNotification',
    props: {
        url: String
    },
    data() {
        return {
            newMessages: false,
            currentUrl: window.location.pathname
        };
    },
    methods: {
        async hasNewMessages(onComplete) {
            const { id } = this.userLoginData;
            const userRef = await db.collection('users').doc(`${id}`);
            const userSnapshot = await userRef.get();
            if (!userSnapshot.exists) {
                userRef.set({
                    username: this.userLoginData?.name || this.userLoginData?.fullname || `${this.userLoginData?.first_name} ${this.userLoginData?.last_name}`,
                    first_name: this.userLoginData?.first_name,
                    last_name: this.userLoginData?.last_name,
                    picture: this.userLoginData?.img || this.userLoginData?.profile_image,
                });
            }

            const subscriber = db
                .collection('chats')
                .where('users', 'array-contains-any', [{ id, deleted: false, deleted_chat: false }])
                .where('deleted', '==', false)
                .onSnapshot((snapshots) => {
                    const unread = snapshots.docs.find(doc => {
                        const data = doc.data();
                        return !data[`${id}_read`];
                    });

                    if (unread) {
                        const newMessages = !!unread;
                        onComplete(newMessages);
                    }
                });
            return () => subscriber();
        },
    },
    mounted() {
        this.hasNewMessages((newMessages) => {
            if (this.currentUrl === "/user/chats") {
                this.newMessages = false;
            } else {
                this.newMessages = newMessages;
            }
        });
    },
    computed: {
        ...mapState(useUserStore, ['userLoginData']),
    },
};
</script>

<style scoped>
.icon-color-disabled {
    color: #aaa;
}
.new-messages-icon {
    position: absolute;
    bottom: 45%;
    left: 70%;
    padding: 1px 7px;
    transform: translateX(-50%) scale(0.7);
    font-size: 18px;
}
</style>