if (!window.SiteHeader) {
    if (!window.$) {
        const jQuery = require('jquery');
        const tokenHolder = document.getElementById('csrf-token');
        if (tokenHolder) {
            jQuery.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': tokenHolder.value
                }
            });
        }
        window.$ = jQuery;
    }

    require('../../public/assets/js/site-header');
}
