var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-menu-global"},[_c('div',{staticClass:"sticky-sidebar"},[_c('nav',{class:{ mobile: _vm.$root.isMobile, tablet: _vm.$root.isTablet }},[_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed"}},[_vm._m(0)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#campaigns"}},[_vm._m(1)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#news"}},[_vm._m(2)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#tv"}},[_vm._m(3)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#petitions"}},[_vm._m(4)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#blogs"}},[_vm._m(5)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#polls"}},[_vm._m(6)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#lobbying"}},[_vm._m(7)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#members"}},[_vm._m(8)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#races"}},[_vm._m(9)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#groups"}},[_vm._m(10)]),_vm._v(" "),_c('a',{staticClass:"sidebar-link",class:{ mobile: _vm.$root.isMobile },attrs:{"href":"/feed#events"}},[_vm._m(11)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-compass icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Explore")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-podium icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Campaigns")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-rss-square icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-video icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Crowdpac TV")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-file-signature icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Petitions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-edit icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Blogs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-poll icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Polls")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-landmark icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Lobbying")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-users icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Members")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-flag-usa icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Races")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-user-friends icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Groups")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-sidebar-menu-item"},[_c('i',{staticClass:"fal fa-calendar-day icon"}),_vm._v(" "),_c('span',{staticClass:"buttons sidebar-menu-title"},[_vm._v("Events")])])
}]

export { render, staticRenderFns }