<template>
  <div>
    <form class="site-search-form" method="GET" :action="formAction" @keydown.esc="common.dismiss">
      <cp-arrow-key-focuser selector="input, a, button:not(.search-arrow)">
        <cp-expander v-if="common.showing" class="search-input-expander" :duration="250">
          <cp-site-search-input class="search-input" name="q" v-model="query"></cp-site-search-input>
        </cp-expander>

        <transition v-if="breakpoints.isMobile" name="fade">
          <div v-if="common.showing" class="backdrop" @click="common.dismiss"></div>
        </transition>

        <transition name="slide">
          <div v-if="common.showing && records.length > 0" class="results-container">
            <cp-site-search-results class="results" :query="query" :results="records" :totalResults="totalRecords"></cp-site-search-results>
          </div>
        </transition>
      </cp-arrow-key-focuser>
    </form>
  </div>
</template>

<script>
import { algoliaGlobalSearch, GLOBAL_SEARCH_INDEXES } from '../../api/algolia';
import { search as searchUsers } from '../../api/users';
import { state as breakpoints } from '../../mixins/breakpoints';
import CpArrowKeyFocuser from '../../arrow-key-focuser';
import CpExpander from '../../expander';
import CpSiteSearchInput from './input';
import CpSiteSearchResults from './results';
import common from './common';
import queryString from '../../lib/query-string';

export default {
  components: {
    CpArrowKeyFocuser,
    CpExpander,
    CpSiteSearchInput,
    CpSiteSearchResults,
  },

  props: {
    formAction: { type: String, default: '/#search' },
    searchIndex: { type: String, default: 'crowdpacs_last_update_desc' },
    previewCount: { type: Number, default: 3 },
  },

  data() {
    return {
      breakpoints,
      common,
      error: null,
      pageSize: 3,
      query: '',
      crowdpacs: [],
      candidates: [],
      petitions: [],
      news: [],
      users: [],
      records: [],
      totalRecords: 0,
      debounceTimeout: NaN,
    };
  },

  mounted() {
    if (window.location.hash === '#search') {
      this.query = queryString.parse(location.search).q;
    }
  },

  methods: {
    async handleInput(query) {
      if (window.location.hash !== '#search') {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(async () => {
          const trimmedQuery = query?.trim() || '';
          if (trimmedQuery === '') {
            this.query = trimmedQuery;
            this.records = [];
          } else {
            try {
              this.query = query;
              this.error = null;
              this.records = [];

              const response = await algoliaGlobalSearch({
                query: query,
                pageSize: this.pageSize,
                crowdpacs: this.crowdpacs,
                candidates: this.candidates,
                petitions: this.petitions,
                news: this.news,
              });

              const crowdpacsData = response.results[GLOBAL_SEARCH_INDEXES.crowdpacs];
              const candidatesData = response.results[GLOBAL_SEARCH_INDEXES.candidates];
              const petitionsData = response.results[GLOBAL_SEARCH_INDEXES.petitions];
              const newsData = response.results[GLOBAL_SEARCH_INDEXES.news];

              const usersData = await searchUsers({
                query: query,
                page: this.users.page,
              });

              this.crowdpacs = crowdpacsData.hits;
              this.candidates = candidatesData.hits;
              this.petitions = petitionsData.hits;
              this.news = newsData.hits;
              this.users = usersData.hits;

              this.totalRecords = this.candidates.length + this.users.length + this.crowdpacs.length + this.news.length + this.petitions.length;

              // The order here is very important! Do not change it
              while (this.records.length < 5 && this.records.length < this.totalRecords) {
                if (this.candidates.length > 0) {
                  this.records.push(this.candidates.pop());
                }

                if (this.crowdpacs.length > 0) {
                  this.records.push(this.crowdpacs.pop());
                }

                if (this.users.length > 0) {
                  this.records.push(this.users.pop());
                }

                if (this.news.length > 0) {
                  this.records.push(this.news.pop());
                }

                if (this.petitions.length > 0) {
                  this.records.push(this.petitions.pop());
                }
              }

              if (this.records.length > 5) {
                this.records = this.records.slice(0, 5);
              }
            } catch (error) {
              console.error(error);
              this.error = error;
            }
          }
        }, 200);
      }
    },
  },

  watch: {
    async ['common.showing'](showing) {
      if (showing) {
        await this.$nextTick();
        const input = this.$el.querySelector('input');
        input.focus();
      }
    },

    query: 'handleInput',
  },
};
</script>

<style scoped>
.site-search-form {
  background: white;
  color: var(--muted);
  font-family: var(--sans-font);
  margin: 0;
  transition: opacity 1s;
}

.search-input-expander {
  border-top: 1px solid gray;
}

.search-input {
  z-index: 3;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.backdrop {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  height: 100vh;
  position: absolute;
  transition: opacity 1s;
  width: 100%;
  z-index: 2;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.results-container {
  position: relative;
  transition: opacity 0.2s, transform 0.1s;
  z-index: 2;
}

.results {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
