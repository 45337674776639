export default function flattenObject(object, _prefix = '') {
    return Object.entries(object).reduce((result, [key, value]) => {
        const valueIsObject = value.constructor === Object

        if (valueIsObject) {
            const nestedResult = flattenObject(value, _prefix + key + '.');
            return Object.assign(result, nestedResult);
        } else {
            result[_prefix + key] = value;
            return result;
        }
    }, {});
}
