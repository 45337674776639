<style scoped>
.cp-icon__container {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.cp-icon__height-finder {
  visibility: hidden;
}

.cp-icon {
  display: block;
  height: 1em;
}
</style>

<template>
  <span class="cp-icon__container" :title="title" :aria-label="$attrs.ariaLabel || title" :style="lineHeight === null ? null : { width: lineHeight }">
    <span v-if="square" ref="lineHeightFinder" class="cp-icon__height-finder" role="decoration">&#8203;</span
    ><!--Zero-width space-->
    <fa-icon class="cp-icon" :name="name" v-bind="$attrs"></fa-icon>
  </span>
</template>

<script>
import FaIcon from 'vue-awesome/components/Icon.vue';
import 'vue-awesome/icons/angle-double-left';
import 'vue-awesome/icons/angle-double-right';
import 'vue-awesome/icons/angle-down';
import 'vue-awesome/icons/angle-left';
import 'vue-awesome/icons/angle-right';
import 'vue-awesome/icons/arrow-down';
import 'vue-awesome/icons/arrow-right';
import 'vue-awesome/icons/arrow-up';
import 'vue-awesome/icons/camera';
import 'vue-awesome/icons/caret-down';
import 'vue-awesome/icons/caret-up';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/check-circle';
import 'vue-awesome/icons/circle';
import 'vue-awesome/icons/cog';
import 'vue-awesome/icons/exclamation-circle';
import 'vue-awesome/icons/external-link-alt';
import 'vue-awesome/icons/brands/facebook-f';
import 'vue-awesome/icons/brands/facebook';
import 'vue-awesome/icons/file';
import 'vue-awesome/icons/filter';
import 'vue-awesome/icons/cog';
import 'vue-awesome/icons/brands/google';
import 'vue-awesome/icons/regular/hand-point-left';
import 'vue-awesome/icons/regular/hand-point-right';
import 'vue-awesome/icons/link';
import 'vue-awesome/icons/long-arrow-alt-right';
import 'vue-awesome/icons/long-arrow-alt-left';
import 'vue-awesome/icons/pause-circle';
import 'vue-awesome/icons/pencil-alt';
import 'vue-awesome/icons/play-circle';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/question-circle';
import 'vue-awesome/icons/comment-alt';
import 'vue-awesome/icons/hand-paper';
import 'vue-awesome/icons/sync-alt';
import 'vue-awesome/icons/search';
import 'vue-awesome/icons/sliders-h';
import 'vue-awesome/icons/stop-circle';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/trash';
import 'vue-awesome/icons/brands/twitter';
import 'vue-awesome/icons/dollar-sign';
import 'vue-awesome/icons/info-circle';
import 'vue-awesome/icons/video';
import 'vue-awesome/icons/video-slash';
import 'vue-awesome/icons/microphone';
import 'vue-awesome/icons/microphone-slash';
import 'vue-awesome/icons/list';
import 'vue-awesome/icons/share';
import 'vue-awesome/icons/share-alt';
import 'vue-awesome/icons/paper-plane';
import 'vue-awesome/icons/volume-up';
import 'vue-awesome/icons/volume-mute';
import 'vue-awesome/icons/comment-dots';
import 'vue-awesome/icons/comment-slash';
import 'vue-awesome/icons/expand';
import 'vue-awesome/icons/compress';
import 'vue-awesome/icons/eye';
import 'vue-awesome/icons/clock';
import 'vue-awesome/icons/pen';
import 'vue-awesome/icons/hourglass-start';
import 'vue-awesome/icons/user-plus';
import 'vue-awesome/icons/user-check';
import 'vue-awesome/icons/ban';
import 'vue-awesome/icons/times-circle';

const DEPRECATED_NAMES_FROM_FONTAWESOME_4 = {
  'external-link': FaIcon.icons['external-link-alt'],
  facebook: FaIcon.icons['brands/facebook-f'],
  'facebook-square': FaIcon.icons['brands/facebook'],
  'file-o': FaIcon.icons['file'],
  gear: FaIcon.icons['cog'],
  google: FaIcon.icons['brands/google'],
  'hand-o-left': FaIcon.icons['regular/hand-point-left'],
  'hand-o-right': FaIcon.icons['regular/hand-point-right'],
  'long-arrow-right': FaIcon.icons['long-arrow-alt-right'],
  'long-arrow-left': FaIcon.icons['long-arrow-alt-left'],
  pencil: FaIcon.icons['pencil-alt'],
  refresh: FaIcon.icons['sync-alt'],
  sliders: FaIcon.icons['sliders-h'],
  twitter: FaIcon.icons['brands/twitter'],
  usd: FaIcon.icons['dollar-sign'],
};

FaIcon.register(DEPRECATED_NAMES_FROM_FONTAWESOME_4);

FaIcon.register({
  eyeball: {
    width: 60,
    height: 40,
    d: `
                M 0 20
                Q 30 -15 60 20
                Q 30 55 0 20
                Z

                M 30 8
                Q 18 8 18 20
                T 30 32
                T 42 20
                T 30 8
                Z

                M 30 15
                Q 35 15 35 20
                T 30 25
                T 25 20
                T 30 15
                Z
            `,
  },
});

export default {
  DEPRECATED_NAMES_FROM_FONTAWESOME_4,

  registeredIcons: Object.keys(FaIcon.icons).sort(),

  components: {
    FaIcon,
  },

  props: {
    name: String,
    title: String, // Applies `aria-label`, if that's not present.
    square: Boolean,
  },

  data() {
    return {
      lineHeight: null,
    };
  },

  methods: {
    handleSquareChange(square) {
      if (square) {
        this.setDimensions();
      } else {
        this.lineHeight = null;
      }
    },

    async setDimensions() {
      await this.$nextTick(); // Wait for children to render.
      if (this.$el.offsetHeight !== 0) {
        // Is this icon currently in the DOM?
        const lineHeight = this.$refs.lineHeightFinder.getBoundingClientRect().height; // Match the height of the text next to it.
        this.lineHeight = `${lineHeight}px`;
      } else {
        // TODO: Have a single mutation watcher and a list of instances to try again?
      }
    },
  },

  watch: {
    name: {
      handler(name) {
        if (name in DEPRECATED_NAMES_FROM_FONTAWESOME_4) {
          const newName = Object.entries(FaIcon.icons).find(([_, icon]) => icon === DEPRECATED_NAMES_FROM_FONTAWESOME_4[name])[0];
          console.warn(`The icon "${name}" is now named "${newName}". Please update your instance.`);
        }
      },
      immediate: true,
    },

    square: {
      handler() {
        this.handleSquareChange(...arguments);
      },
      immediate: true,
    },
  },
};
</script>
