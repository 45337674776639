<!--
    This provides a pretty naïve up-and-down focus-by-arrow-key feature for any descendents matching a selector.
    It might be worth setting up a more full-featured implementation in the future.
    E.g. https://github.com/brian-c/focus-by-arrow
-->

<template>
    <div @keydown.up="handleArrowKey($event, -1)" @keydown.down="handleArrowKey($event, +1)">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        selector: { type: String, required: true }
    },

    methods: {
        handleArrowKey(event, movement) {
            const allArrowableElements = [...this.$el.querySelectorAll(this.selector)];
            const availableArrowableElements = allArrowableElements.filter(element => !element.disabled && element.tabIndex !== -1);
            const activeIndex = availableArrowableElements.indexOf(document.activeElement);
            const indexToFocus = Math.max(0, Math.min(activeIndex + movement, availableArrowableElements.length - 1));

            if (indexToFocus !== activeIndex) {
                availableArrowableElements[indexToFocus].focus();
                event.preventDefault();
            }
        }
    }
};
</script>
