<template>
  <div class="results">
    <template v-if="results.length === 0">
      <p class="no-results">We weren't able to find anything. Try <a href="/feed">exploring campaigns</a>.</p>
    </template>

    <template v-else>
      <ul class="results-list">
        <li v-for="result in results" :key="result.objectID" class="results-item">
          <a v-if="result.objectType === 'crowdpac'" :href="result.permalink" class="results-link">
            <div>
              <div class="results-image" :style="{ backgroundImage: `url('${result.image}')` }"></div>
            </div>
            <div>
              <div class="results-title">
                <template v-if="result.claimed">
                  {{ result.recipients[0].name }}
                </template>
                <template v-else>
                  {{ result.name }}
                </template>
              </div>
              <template v-if="result.claimed">
                {{ result.recipients[0].subtitle }}
              </template>
              <template v-else>
                <template v-for="(recipient, i) in result.recipients.slice(0, recipientsToShow)">
                  {{ recipient.name }}{{ i < result.recipients.length - 1 ? ',' : null }}
                </template>
                <span
                  v-if="result.recipients.length > recipientsToShow"
                  :title="
                    result.recipients
                      .slice(recipientsToShow)
                      .map(r => r.name)
                      .join(', ')
                  "
                >
                  and {{ result.recipients.length - recipientsToShow }} more
                </span>
              </template>
            </div>
          </a>
          <a v-else :href="result.url" class="results-link">
            <div>
              <div class="results-image" :style="{ backgroundImage: `url('${result.image}')` }"></div>
            </div>
            <div>
              <div class="results-title">
                {{ getRecordTitle(result) }}
              </div>
              <div v-if="results.objectType == 'candidate'">{{ result.race }}</div>
              <div>{{ getRecordType(result) }}</div>
            </div>
          </a>
        </li>
      </ul>

      <div>
        <button type="submit" class="show-all-button">
          <strong>See all {{ totalResults }} matches for “{{ query }}”</strong>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    query: String,
    results: Array,
    totalResults: Number,
    recipientsToShow: { type: Number, default: 5 },
  },
  methods: {
    getRecordTitle(record) {
      switch (record.objectType) {
        case 'crowdpac':
          return record.name;
        case 'candidate':
          return record.full_name;
        case 'petition':
          return record.title;
        case 'news':
          return record.title;
        case 'user':
          return record.fullname;
        default:
          break;
      }
    },
    getRecordType(record) {
      switch (record.objectType) {
        case 'crowdpac':
          return 'Campaign';
        case 'candidate':
          return 'Candidate';
        case 'petition':
          return 'Petition';
        case 'news':
          return 'News';
        case 'user':
          return 'User';
        default:
          return '';
      }
    },
    getRecordLinkText(record) {
      switch (record.objectType) {
        case 'crowdpac':
          return 'View Campaign';
        case 'candidate':
          return record.race && record.race.length ? record.race : 'View Candidate';
        case 'petition':
          return 'View Petition';
        case 'news':
          return 'View Article';
        case 'user':
          return 'View Profile';
        default:
          return 'View';
      }
    },
  },
};
</script>

<style scoped>
.results {
  background: var(--background);
  border: 1px solid var(--light-gray);
  color: var(--muted);
}

.no-results {
  font: inherit;
  margin: 10px;
  text-align: center;
}

.results-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.results-item {
  margin: 0;
  padding: 0;
}

.results .results-link {
  /* Work around specificity issue from site-header. */
  align-items: center;
  border-bottom: 1px solid var(--light-gray);
  color: inherit;
  display: flex;
  padding: 20px;
}

.results .results-link:hover {
  text-decoration: none;
}

.results-link:hover {
  background: rgba(127, 127, 127, 0.1);
}

.results-image {
  background-color: var(--light-gray);
  border-radius: 50%;
  background-size: cover;
  height: 50px;
  margin-right: 20px;
  width: 50px;
}

.results-title {
  color: var(--color);
  font: lighter 18px var(--serif-font);
}

.results-description {
  color: var(--muted);
  font-size: 14px;
}

.show-all-button {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: var(--highlight);
  font: inherit;
  padding: 20px;
  width: 100%;
}

.show-all-button:hover {
  background: rgba(127, 127, 127, 0.1);
  text-decoration: none;
}
</style>
