<!--
    A GridItem is responsible for defining the width of its content.
    Use the `span` prop to do this.
    A couple special `span` values:
    - "content": The item wraps to its content (which is the default behavior).
    - "auto": The items fills any available space in its Grid row.

    Instead of the `no-margin` prop, consider setting `tight` on the parent Grid.
-->

<style>
.grid__item--content {
    flex-grow: 0;
    flex-shrink: 0;
}

@media screen and (max-width: 768px) {
    .grid__item--content {
        flex-shrink: 1;
    }
}

.grid__item--auto {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.grid__item--no-margin {
    margin: 0;
}
</style>

<template>
    <div :class="className" :style="style"><slot /></div>
</template>

<script>
import Grid from './grid';

const CSS_VAR_SUPPORT = 'CSS' in window && CSS.supports('color', 'var(--custom-property)');
const GUTTER_FALLBACK = '20px';

export default {
    props: {
        span: { type: [Number, String], default: 'content' }, // Number of columns, or "auto"/"content" to fill/not-fill available space.
        align: String,
        offset: {type: [Number,String], default: 0},
        noMargin: Boolean,
        columns: { default: 12 }, // Changing this should be pretty rare.,
        fullWidth: Boolean,
        mobileTopPadding: Boolean
    },

    computed: {
        className() {
            return {
                grid__item: true,
                'grid__item--content': this.span === 'content',
                'grid__item--auto': this.span === 'auto',
                'grid__item--no-margin': this.noMargin
            };
        },

        style() {
            let marginLeft;
            let offsetFloat = parseFloat(this.offset);
            if(parseFloat(this.offset) !== 0 ){
              marginLeft = ((offsetFloat / 12) * 100 ).toString() + '%';
            }
            let width;
            if (this.span !== 'content' && this.span !== 'auto') {
                const percent = parseFloat(this.span) / parseFloat(this.columns);
                if (this.fullWidth) {
                    width = '100%';
                } else {
                    if (CSS_VAR_SUPPORT) {
                        width = `calc(${percent} * var(--grid-width) - var(--grid-gutter))`;
                    } else {
                        width = `calc(${percent} * 100% - ${GUTTER_FALLBACK})`;
                    }
                }
            }
            let paddingTop;
            if (this.mobileTopPadding) {
                paddingTop = '60px';
            }

            const alignSelf = Grid.alignmentValues[this.align];

            return { width, alignSelf, paddingTop, marginLeft };
        }
    }
};
</script>
