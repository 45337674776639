export default{
  methods: {
    imgAdjustedWithVersion(image){
      return image && image.settings && image.settings.height && image.settings.width &&
      image.settings.x != null && image.settings.y !=null && image.settings.version;
    },
    imgAdjustedNoVersion(image){
      return image && image.settings && image.settings.height && image.settings.width &&
      image.settings.x != null && image.settings.y !=null;
    },
    imgNotAdjusted(image){
      return image && image.settings;
    },
    imgNotAdjustedWithVersion(image){
      return image && image.settings && image.settings.version
    },
    croppedImgWithVersion(image, height,width){
      return `https://res.cloudinary.com/crowdpac/image/upload/c_fill,f_auto,h_${height},q_auto,w_${width},x_${Math.floor(image.settings.x)},y_${Math.floor(image.settings.y)}/v${image.settings.version}/${image.id}`
    },
    imgWithVersion(image){
      return `https://res.cloudinary.com/crowdpac/image/upload/v${image.settings.version}/${image.id}`
    },
    croppedImgNoVersion(image,height,width){
      return `https://res.cloudinary.com/crowdpac/image/upload/c_fill,f_auto,h_${height},q_auto,w_${width},x_${Math.floor(image.settings.x)},y_${Math.floor(image.settings.y)}/v1/${image.id}`
    }
  }
}
