<template>
    <span v-if="customMessage === 'hide'"></span>
    <div class="banner" v-else-if="customMessage !== 'hide' && customMessage !== ''" v-html="customMessage"></div>
    <div class="banner" v-else>
        On {{ date }}, Crowdpac will be closing. For more information,
        <a
            :href="articleLink"
        >click here</a>.
    </div>
</template>

<script>
export default {
    props: {
        date: String,
        articleLink: String,
        customMessage: String,
    }
};
</script>

<style scoped>
.banner {
    background-color: #ff4814;
    width: 100%;
    text-align: center;
    padding: 14px 0px;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.7px;
}
.site-header .banner a, .site-header .banner >>> a {
    font-weight: bold;
    color: #fff;
}
@media (max-width: 768px) {
    .banner {
        font-size: 14px;
    }
}
</style>
