<template>
    <div class="query-container">
        <span class="search-icon">
            <cp-icon name="search"></cp-icon>
        </span>

        <input
            type="text"
            class="query-input"
            :name="name"
            v-model="query"
            placeholder="Search Crowdpac"
            autocomplete="off"
            @input="handleInput"
        />

        <button type="submit" v-if="query" class="search-arrow">
            <cp-icon name="arrow-right"></cp-icon>
        </button>
    </div>
</template>

<script>
import CpIcon from '../../icon';

export default {
    components: {
        CpIcon,
    },

    props: {
        value: String,
        name: String,
        debounce: { type: Number, default: 250 },
    },

    data() {
        return {
            query: this.value,
            debounceTimeout: NaN,
        };
    },

    methods: {
        handleInput(event) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.$emit('input', this.query);
            }, this.debounce);
        },
    },
};
</script>

<style scoped>
.query-container {
    font-size: 20px;
    line-height: 1.2;
    padding: 3px;
    position: relative;
}

.search-icon {
    font-size: 24px;
    left: calc(1.3vw + 2px);
    opacity: 0.7;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.query-input {
    border: 0;
    box-shadow: none;
    box-sizing: border-box;
    display: block;
    font: inherit;
    height: auto;
    line-height: inherit;
    margin: 0;
    padding: 13px calc(2.6vw + 1.2em - 4px) 11px;
    padding-right: 30px;
    width: 100%;
}

.query-input:focus {
    border: 0;
    box-shadow: none;
    outline: none !important;
}
.site-header .inline-button:focus,
.site-header i:focus {
    outline: none !important;
}

.search-arrow {
    background: transparent;
    border: 0;
    color: var(--highlight);
    font: inherit;
    position: absolute;
    right: calc(1.3vw + 2px);
    top: 50%;
    transform: translateY(-50%);
}
</style>
