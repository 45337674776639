import axios from 'axios';
import jQuery from 'jquery';
import { mapActions } from 'pinia';

import { useUserStore } from '../../../store/useUserStore.js';
import fire from '../firebase';
const db = fire.firestore();

export default {
    async created() {
        // For the site header:
        const $ = jQuery;
        this._updateLegacyDOM();
        var preloadedResponse = $('#js-user-check-response-preload').data('response');
        if (preloadedResponse) {
            return this._handleCheckResponse({ data: preloadedResponse });
        }

        const userLoginDataFromStorage = JSON.parse(localStorage.getItem('userLoginData'));
        const loginDate = userLoginDataFromStorage?.loginDate;
        let loginAfter3min = false;
        if (loginDate) {
            const loginDatemin = (Date.now() - parseInt(loginDate)) / (1000 * 60);
            loginAfter3min = loginDatemin > 3;
        }

        const checkUserCondition = !userLoginDataFromStorage || userLoginDataFromStorage?.logged_in === 0 || !this.authUser || loginAfter3min;
        if (checkUserCondition) {
            await this.checkUserLogin(this._handleCheckResponse);
        }
    },

    data: {
        authUserPending: true, // false if we know from PHP
        authUser: null, // how do we get this from PHP if we know it?
        areNotificationsUnread: false,
        notificationCount: 0,
    },

    watch: {
        areNotificationsUnread(newValue, oldValue) {
            if (newValue) {
                $('.js-sitewide-replace-user-notifications-count').text(this.areNotificationsUnread ? this.notificationCount : 0);
                $('.js-sitewide-replace-attr-user-notifications-count').attr('data-user-notifications-count', this.areNotificationsUnread ? this.notificationCount : 0);
            }
        }
    },

    mounted() {
        this.$watch('authUser', (newValue, oldValue) => {
            if (newValue) {
                this.handleNotifications();
            }
        });
    },

    methods: {
        ...mapActions(useUserStore, ['checkUserLogin']),
        redirectToLogin(url) {
            if (url === null) {
                url = window.location.href;
            }
            window.location.href = "/user/login?redir=" + encodeURIComponent(url);
        },

        _handleCheckResponse(response) {
            if (response.data.logged_in === 1) {
                // user is logged in! fill the user data
                this.authUser = response.data;
                this._updateLegacyDOM();
            }
            this.authUserPending = false;
        },
        handleNotifications() {
            const lastTimeNotificationsOpenedString = localStorage.getItem('lastTimeNotificationsOpened');
            const lastTimeNotificationsOpened = parseInt(lastTimeNotificationsOpenedString, 10);
            let notifications = [];
            db.collection('users').doc(`${this.authUser.id}`)
                .collection('notifications')
                .where('read', '==', false)
                .onSnapshot((snapshots) => {
                    if (snapshots) {
                        const changes = snapshots.docChanges();
            
                        for (let index = 0; index < changes.length; index++) {
                            if (changes[index].type === 'added') {
                                const notification = changes[index].doc.data();
                                const date = notification.created_at?.toDate();
                                notifications.push(notification);
                                this.notificationCount = notifications.length;
                                if (date > new Date(lastTimeNotificationsOpened)) {
                                    this.areNotificationsUnread = true;
                                }
                            }
                        }
                    }
                });
        },

        _updateLegacyDOM() {
            // For the site header:
            const $ = jQuery;

            $('.show-if-logged-in')[this.authUser ? 'show' : 'hide']();
            $('.show-if-logged-out')[this.authUser ? 'hide' : 'show']();

            if (this.authUser) {
                document.documentElement.classList.add('is-logged-in');
                $('.js-sitewide-replace-user-name').text(this.authUser.name);
                $('.js-sitewide-replace-user-img').prop('src', this.authUser.img);
                $('.js-sitewide-replace-user-notifications-count').text(this.areNotificationsUnread ? this.notificationCount : 0);
                $('.js-sitewide-replace-attr-user-notifications-count').attr('data-user-notifications-count', this.areNotificationsUnread ? this.notificationCount : 0);
                if (this.authUser.campaigns_url === null) {
                    $('.js-sitewide-my-campaigns-link').hide();
                } else {
                    $('.js-sitewide-my-campaigns-link').show();
                }
            }
        }
    }
};
