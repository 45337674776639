import Vue from 'vue';

const IPAD_PORTRAIT = 768;
const IPAD_PRO = 1024;
const IPHONE_PORTRAIT = 414;
const SMALL_DESKTOP = 1150;

const mixin = {
    created() {
        addEventListener('resize', this._breakpointsHandleResize);
    },

    beforeDestroy() {
        removeEventListener('resize', this._breakpointsHandleResize);
    },

    data() {
        return { innerWidth };
    },

    computed: {
        isDesktop() {
            return this.innerWidth > IPAD_PORTRAIT;
        },

        isSmallDesktop() {
            return this.innerWidth <= SMALL_DESKTOP;
        },

        isMobile() {
            return this.innerWidth <= IPAD_PORTRAIT;
        },

        isTablet() {
            return this.innerWidth > IPHONE_PORTRAIT && this.innerWidth <= IPAD_PRO;
        },

        isPhone() {
            return this.innerWidth <= IPHONE_PORTRAIT;
        }
    },

    methods: {
        _breakpointsHandleResize() {
            this.innerWidth = innerWidth;
        }
    }
};

export default mixin;

export const state = new Vue({
    mixins: [mixin]
});
