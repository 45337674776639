const CSS_VAR_SUPPORT = 'CSS' in window && CSS.supports('color', 'var(--custom-property)');
const GUTTER_FALLBACK = '20px';

function setAnyMargin(sides, element, binding) {
    // Default to 1, but it appears we've sometimes used a literal empty string to imply 0.
    const value = binding.value === undefined ? 1 : (binding.value || 0);

    let marginValue;
    if (value == 'auto') {
        marginValue = 'auto';
    } else if (CSS_VAR_SUPPORT) {
        marginValue = `calc(var(--gutter) * ${value})`;
    } else {
        marginValue = `calc(${GUTTER_FALLBACK} * ${value})`;
    }

    sides.forEach(side => {
        element.style[side] = marginValue;
    });
}

export const margin = setAnyMargin.bind(null, ['marginTop', 'marginBottom']);
export const marginBottom = setAnyMargin.bind(null, ['marginBottom']);
export const marginTop = setAnyMargin.bind(null, ['marginTop']);
export const marginLeft = setAnyMargin.bind(null, ['marginLeft']);
export const marginRight = setAnyMargin.bind(null, ['marginRight']);
