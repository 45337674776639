<!--
    This component is responsible for putting boxes next to each other.
    A Grid is a wrapper for a set of GridItems.

    The `span` prop of GridItem describes its width.
    Once a sum of `span` props exceeds 12, items will wrap to the next line.

    A Grid's items can be forced into one scrolling line by setting the `scroll` prop.

    By default, A Grid's items have a gutter of space between them.
    You can remove this gutter with the Grid's `tight` prop.
    TODO: Have `tight` take a number, kinda like the v-margin directive.
    In the meantime you can customize the gutter by overriding the `--grid-gutter` CSS variable inline.
    e.g. <Grid style="--grid-gutter: 0.5ch;">

    Sometimes you might want to display items stacked into a column (e.g. on mobile).
    Instead of using the grid's `column` prop, consider setting the items' `span` props to 12.
    This simplifies the responsibility of the Grid and ensures the items' `span` always makes sense.

    Before using the `reverse` prop, consider the accessibility implications.
    It might make more sense to reorder the elements in the template.
-->

<style>
    .grid__wrapper {
        --grid-gutter: var(--gutter, 10px);
        display: flow-root;
    }
    .grid__wrapper--spread {
        display: flex;
        flex-grow: 1;
    }
    .grid__wrapper--spread > .grid {
        flex-grow: 1;
    }
    .grid {
        --grid-width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: calc(var(--grid-gutter) / -2); /* Cancel out the margin on the items. */
        padding: 0;
    }

    .grid--column {
        flex-direction: column;
    }

    .grid--column-reverse {
        flex-direction: column-reverse;
    }

    .grid--reverse {
        flex-direction: row-reverse;
    }

    .grid__wrapper--tight {
        --grid-gutter: -1px; /* Cancel out any borders. */
    }

    .grid--scroll {
        --grid-width: 95%; /* Make it more obvious that there's more to scroll. */
        flex-wrap: nowrap;
        margin-right: 0;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }

    .grid > * {
        margin: calc(var(--grid-gutter) / 2); /* Flexed margins don't collapse, so each item provides half its gutter. */
    }

    .grid--stretch-content > * {
        display: flex;
    }

    .grid--stretch-content > * > * {
        flex-grow: 1;
    }

    .grid--no-margin {
        margin: 0;
    }
</style>

<template>
    <div :class="`grid__wrapper ${tight ? 'grid__wrapper--tight' : ''} ${spread ? 'grid__wrapper--spread' : ''}`"><!-- Allow margin to be tweaked from outside. -->
        <div :class="className" :style="style">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        alignmentValues: {
            top: 'flex-start',
            center: 'center',
            bottom: 'flex-end',
            baseline: 'baseline',
            stretch: 'stretch',
        },

        justifyValues: {
            top: 'flex-start',
            center: 'center',
            bottom: 'flex-end',
            spaceAround: 'space-around',
            spaceBetween: 'space-between'
        },

        directionValues: {
            rowReverse: 'row-reverse',
            row: 'row',
            column: 'column',
            columnReverse: 'column-reverse',
        },

        props: {
            align: String,
            justify: String,
            column: Boolean,
            reverse: Boolean,
            columnReverse: Boolean,
            tight: Boolean,
            scroll: Boolean,
            stretchContent: Boolean,
            noMargin: Boolean,
            direction: String,
            spread: Boolean,
        },

        computed: {
            className() {
                return {
                    'grid': true,
                    'grid--scroll': this.scroll,
                    'grid--column': this.column,
                    'grid--reverse': this.reverse,
                    'grid--column-reverse': this.columnReverse,
                    'grid--stretch-content': this.stretchContent,
                    'grid--no-margin': this.noMargin
                };
            },

            style() {
                return {
                    alignItems: this.$options.alignmentValues[this.align],
                    justifyContent: this.$options.justifyValues[this.justify],
                    flexDirection: this.$options.directionValues[this.direction],
                }
            }

        }
    };
</script>
