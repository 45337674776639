<script>
export default {
    name: 'CpHeaderMenuTooltip',
    components: {
        CpBaseSvgIcon: require('../base-svg-icon').default,
        CpButtonLink: require('../button-link').default,
        CpButton: require('../button').default,
        CpGrid: require('../grid').default,
        CpGridItem: require('../grid-item').default,
    },
    directives: {
        marginBottom: require('../directives/margins').marginBottom,
        marginRight: require('../directives/margins').marginRight,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
};
</script>

<template>
    <div class="how-works-container">
        <h3 v-if="title" class="headline neutral6-color title">{{ title }}</h3>
        <a v-for="item in items" :key="item.title" :href="item.url" class="how-works-item">
            <div class="how-works-icon-container"><i :class="['fal', item.icon, 'primary-color, icon']"></i></div>
            <div>
                <h3 class="buttons black-color">{{ item.title }}</h3>
                <p class="footnote neutral4-color">{{ item.description }}</p>
            </div>
        </a>
    </div>
</template>

<style scoped>
.title {
    padding: 8px 16px;
    margin-right: 8px;
    border-bottom: 1px solid var(--neutral3);
    width: 100%;
}

.how-works-container {
    display: none;
    flex-wrap: wrap;
    max-width: 900px;
    border-radius: 4px;
    padding: 8px 0px 8px 16px;
    background-color: var(--white);
    box-shadow: 0px 2px 3px 0px #0000004D;
    z-index: 9999;
}

.how-works-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--neutral2);
    width: 32%;
    margin-bottom: 8px;
    margin-right: 8px;
    white-space: normal;
    text-decoration: none !important;
}

.how-works-item:hover {
    background-color: var(--primary2);
}

.how-works-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: var(--primary2);
    border-radius: 50%;
    margin-right: 8px;
    padding: 0 8px;
}

.icon {
    font-size: 28px;
}

@media screen and (max-width: 1250px) {
    .how-works-container {
        max-width: 500px;
    }

    .how-works-item {
        width: 48%;
    }
}

@media screen and (max-width: 767px) {
    .how-works-container {
        max-width: 300px;
        overflow: scroll;
        max-height: 650px;
    }

    .how-works-item {
        width: 100%;
    }

    .icon {
        font-size: 24px;
    }
}
</style>