<!--
    This component is responsible for drawing a box!
    The box can be defined by an outline or background color, or both.
    The box should act like a block-level element,
    having its width typically defined by its container
    and its height typically defined by its content.

    Here are some common patterns:
    - Box > BoxContent (Contents in a box with some padding)
    - Box > img[width="100%"] + BoxContent (Full-width header image followed by content)
    - Box > BoxContent + hr + BoxContent (Contents separated by a full-width rule)
    - Grid > GridItem > Box > BoxContent (A grid of boxes)

    Sometimes boxes are toggleable, acting like radio buttons or checkboxes.
    Wrap them in a <LabelAsInput> component to take advantage of both
    the browser's built-in form handling behavior
    and Vue's built-in input event binding features (e.g. `v-model=""` and `@input=""`).
-->

<style>
    .box {
        background: var(--background);
        border-radius: 5px;
        color: var(--color);
        overflow: hidden;
        /*padding: 1px 0;  Don't collapse first or last child's margins. */
        transition: all 400ms;
    }

    .box--squared {
        border-radius: 0px;
    }

    .box--centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .box--spread {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .box--outlined {
        border: 1px solid #DBDBDB;
    }

    .box--shadowed {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }

    .box--error {
        border: 1px solid red;
    }

    .box--border-bottom {
        border-bottom: 1px solid #DBDBDB;
        border-radius: 0;
    }

    .box--border-top {
        border-top: 1px solid #DBDBDB;
    }

    .box--border-left {
        border-left: 1px solid #DBDBDB;
    }

    .box--border-right {
        border-right: 1px solid #DBDBDB;
    }

    .box--shaded {
        background: rgba(127, 127, 127, 0.1);
    }

    .box--highlighted {
        border-color: var(--highlight);
        box-shadow: 0 0 0 1px var(--highlight) inset;
    }

    .box--highlighted-thick {
        border-color: var(--highlight);
        box-shadow: 0 0 0 2px var(--highlight) inset;
    }

    .box--light-shaded {
        background: #F6F6F6;
    }

    .box--striped {
        background: repeating-linear-gradient(
            -45deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1) 0.5rem,
            rgba(255, 255, 255, 0.3) 0.5rem,
            rgba(255, 255, 255, 0.3) 1rem
        );
    }

    .box--tall {
        min-height: 200px;
    }

    .box--short {
        min-height: 0px;
    }

    .box--square {
        border-radius: 0px;
    }

    .box--hover:hover {
        border-color: var(--highlight);
        box-shadow: 0 0 0 1px var(--highlight) inset;
    }

    .box--light-hover:hover {
        border-color: #a7a7a7;

    }

    .box--has-overflow{
        overflow: visible;
    }

    /* Not ideal, but a direct img child is probably meant to be full bleed. */
    /* We'll reverse some margins to get past that extra pixel of padding. */
    .box > img {
        display: block;
        margin-bottom: -1px;
        margin-top: -1px;
    }
</style>

<template>
    <div :class="className">
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            centered: Boolean,
            spread: Boolean,
            outlined: Boolean,
            shadowed: Boolean,
            shaded: Boolean,
            highlighted: Boolean,
            highlightedThick: Boolean,
            lightShaded: Boolean,
            hasOverflow: Boolean,
            striped: Boolean,
            borderBottom: Boolean,
            borderTop: Boolean,
            borderLeft: Boolean,
            borderRight: Boolean,
            tall: Boolean,
            short: Boolean,
            square: Boolean,
            hover: Boolean,
            lightHover: Boolean,
            error: Boolean,
            squared: Boolean,
        },

        computed: {
            className() {
                return {
                    'box': true,
                    'box--squared': this.squared,
                    'box--centered': this.centered,
                    'box--spread': this.spread,
                    'box--outlined': this.outlined,
                    'box--shadowed': this.shadowed,
                    'box--shaded': this.shaded,
                    'box--highlighted': this.highlighted,
                    'box--highlighted-thick': this.highlightedThick,
                    'box--light-shaded': this.lightShaded,
                    'box--striped': this.striped,
                    'box--border-bottom': this.borderBottom,
                    'box--border-top': this.borderTop,
                    'box--border-left': this.borderLeft,
                    'box--border-right': this.borderRight,
                    'box--tall': this.tall,
                    'box--short': this.short,
                    'box--square': this.square,
                    'box--hover': this.hover,
                    'box--light-hover': this.lightHover,
                    'box--error': this.error,
                    'box--has-overflow': this.hasOverflow,
                }
            }
        }
    };
</script>
